<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">تعديل الماركة</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <form @submit.prevent="UpdateBrand">
                  <div class="form-group">
                    <label style="padding: 5px"
                      >Name <span class="star">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="brand.name"
                      placeholder="الاسم باللغة الإنجليزية"
                      required
                    />
                    <div v-if(errors.name) id="validateName" class="invalid-feedback">
                      {{errors.name}}
                    </div>
                  </div>

                  <div class="form-group">
                    <label style="padding: 5px"
                      >الاسم <span class="star">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="brand.name_ar"
                      placeholder="الاسم باللغة العربية"
                      required
                    />
                  </div>
                  <button type="submit" class="btn btn-success">
                    تحديث البيانات
                  </button>
                  &emsp;
                  <CButton color="warning" @click="goBack">رجوع</CButton>
                </form>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "EditBrand",
  data() {
    return {
      brand: [],
      image: "",
      errors: []
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.$http
      .get(`${process.env.VUE_APP_URL}brands/${this.$route.params.id}/edit`)
      .then((response) => {
        this.brand = response.data.data;
      });
  },
  methods: {
    UpdateBrand() {
      let currentObj = this;
      let formData = new FormData();
      formData.append("name", this.brand.name);
      formData.append("_method", "PUT");
      this.$http
        .post(
          `${process.env.VUE_APP_URL}brands/${this.$route.params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
            currentObj.$router.push({ path: "/brands" });
          } else {
            currentObj.errors = response.data.data;
          }
        });
    },
    goBack() {
      this.brandsOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/brands" });
    },
  },
};
</script>
